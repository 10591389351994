/**
 * Auth Routes.
 */

// LOG_IN Route
export const LOG_IN = "/token";

// SOCIAL_MEDIA_LOGIN Route
export const SOCIAL_MEDIA_LOGIN =
  "api/Applications/SocialMedia/RegisterAzureProfile";

// PASSWORD_CHANGE Route
export const PASSWORD_CHANGE = "/api/ChangePassword/ChangePassword";

// FORGOT_PASSWORD Route
export const FORGOT_PASSWORD = "/api/ForgotPassword";

// NEW_PASSWORD Route
export const NEW_PASSWORD = "/api/NewPassword";

// APPLICATION_LOG Route
export const APPLICATION_LOG = "/api/Applications/logs";

/**
 *
 * Profile Page Routes.
 */
// PERMISSION Route
export const PERMISSION = "/api/Applications/Permissions";

// User Features Route
export const USER_FEATURES = "/api/Applications/Users/{userId}/Features";
/**
 * Product Page Routes.
 */

/**
 * Profile Page Routes.
 */

// Application Key Route
export const APPLICATION_KEY = "/api/Applications/Keys";

// User Features Route
export const APPLICATION_USER = "/api/Applications/Users";

/**
 * Autest Routes.
 */

// Autest load commands Route
export const AUTEST_LCS = "/api/Applications/LoadCommands";

// Autest Test Route
export const AUTEST_TEST = "/api/Applications/AuTest/Tests";

// Application flow Route
export const App_Flow = "/api/Applications/Autest/AF";

// Application flow Details Steps Route
export const App_Flow_Dtl = "/api/Applications/Autest/AF/{afId}/Details";

// Autest Test Argument Route
export const AUTEST_TEST_ARG =
  "/api/Applications/AuTest/Tests/{testId}/Arguments";

// Autest Test Steps Route
export const AUTEST_TEST_STEP = "/api/Applications/AuTest/Tests/{testId}/Steps";

// Autest Test Cases Route
export const AUTEST_TEST_CASE = "/api/Applications/AuTest/Tests/{testId}/Cases";

// Autest Test Case Arguments Route
export const AUTEST_TEST_CASE_ARG =
  "/api/Applications/AuTest/Tests/Cases/{testCaseId}/Arguments";

// Autest Remote Server Route
export const AUTEST_REMOTE_SERVER = "/api/Applications/AuTest/RemoteServer";

// Autest Run Set Route
export const AUTEST_RUN_SET = "/api/Applications/AuTest/Runsets";

// Autest Run Set Details Route
export const AUTEST_RUN_SET_DETAIL =
  "/api/Applications/AuTest/Runsets/{runId}/Details";

// Autest Run Set Arguments Route
export const AUTEST_RUN_SET_ARGUMENT =
  "/api/Applications/AuTest/RunSets/{runId}/Arguments";

// Autest Executions Route
export const AUTEST_EXECUTIONS = "/api/Applications/AuTest/Execution";

/**
 * RF++ Routes.
 */

// RF Device Enrollment screen Route
export const RF_CONFIGURATION = "/api/Applications/RF/Profiles";

// RF Device Registration Route
export const RF_DEVICE_REGISTRATION =
  "/api/Applications/RF/Devices/Registration";

// RF Profile Group Route
export const RF_PROFILE_GROUP = "/api/Applications/RF/Profile/Group";

// RF Profile Header Route
export const RF_PROFILE = "/api/Applications/RF/Profile/Header";

// RF Profile Setup Route
export const RF_PROFILE_SETUP = "/api/Applications/RF/Profile/Setup";

// RF Field Info Route
export const RFFIELDINFO = "/api/Applications/RF/Fields/Info";

// RF Form Info Route
export const RFFORMINFO = "/api/Applications/RF/Forms/Info";

// RF TC Form Info Route
export const RF_TC_FORM_FIELD = "/api/Applications/RF/TC/Forms/Field";

/**
 *  Organization Page Routes.
 */

// Organization Route
export const ORGANIZATION = "/api/Applications/Organizations";

// Organization Product Route
export const ORGANIZATION_PRODUCTS =
  "/api/Applications/Permissions/Organizations/{orgId}/Products";

// Organization Product Feature Route
export const ORGANIZATION_FEATURE =
  "/api/Applications/Permissions/Organizations/{orgId}/Features";

// Organization Pending Approval Route
export const ORGANIZATION_PENDING_APPROVAL =
  "/api/Applications/Permissions/Organizations/Users/PendingApproval";

// Organization User Route
export const ORGANIZATION_USER =
  "/api/Applications/Organizations/{orgId}/Users";

// Organization User InActive Route
export const ORGANIZATION_UN_APPROVED_USER = "/api/Applications/Users/Inactive";

// Get Lookup By Type Route
export const LOOKUP_BY_TYPE = "/api/Lookup/GetLookupByType";
